const techs = {
    html: {
        name: 'HTML',
        skills: [
            'Структура и поток документа',
            'Семантика',
            'Работа с формами',
            'Работа с изображениями',
            'Вставка iframe из сторонних ресурсов',
        ]
    },
    css: {
        name: 'CSS',
        skills: [
            'Именование классов согласно БЭМ',
            'Блочная и строчная модель',
            'Специфичность и принцип каскада',
            'Наследование',
            'Псевдоклассы и псевдоэлементы',
            'Относительные единицы измерения',
            'Анимации и трансформации',
            'Позиционирование элементоа',
            'Flexbox',
            'Grid layout',
            'Медиазапросы и кастомные свойства',
        ]
    },
    js: {
        name: 'Java Script',
        skills: [
            'Порядок выполнения скрипта',
            'Работа с разными типами данных',
            'Function expression, function declaration, замыкания, рекурсия',
            'BOM, DOM',
            'Fetch, Cookies, LocalStorage SessionStorage',
            'Работа с элементами DOM',
            'Ассинхронный JS',
            'ООП',
        ]
    },
    react: {
        name: 'React',
        skills: [
            'JSX',
            'React элементы и их рендер',
            'React компоненты, использую функциональный подход',
            'Жизненный цикл компонента, пропсы, стейт-переменные',
            'Работа с формами, их валидация',
            'Маршрутизация приложения',
        ]
    },
    node: {
        name: 'Node.js',
        skills: [
            'Модули, импорт, экспорт',
            'NPM, установка, добавление скриптов',
            'Знаком с созданием сервера на Express, роутинг и мидлвары',
            'Знаком с работой с БД MongoDB, создание схем и моделей, подключение к серверу'
        ]
    },
    github: {
        name: 'GitHub',
        skills: [
            'Коммит изменений',
            'Работа с удаленным репозиторием: связь, отправка, запрос изменений',
            'Работа с ветками, создание, мерж',
            'Стеш',
        ]
    },
}

export default techs
